import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { Csku } from "@commonsku/styles";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Generic;
const GenericCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { cell, tooltipText, tooltipOptions } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("Generic Cell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const tooltipAttributes = useTooltipAttributes(tooltipText, REPORT_TOOLTIP_TARGET_ID, tooltipOptions);

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>{cell}</div>
    </Csku>
  );
};

export default GenericCell;
